* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  background-color: #f2f2f2;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 400;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  min-width: 1200px;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  cursor: pointer;
  text-decoration: none;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h1,
h2,
h3 {
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
}

label a {
  border-bottom: 1px solid #d2dee0;
}

b,
em,
strong {
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
}

a {
  font-family: 'ProximaSoft', sans-serif;
  color: #337ab7;
}

em {
  font-style: italic;
}
