@keyframes zoomIn {
  from,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  80% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.zoomIn {
  animation: zoomIn 0.25s both;
}

.zoomOut {
  animation: zoomOut 0.25s ease-in both;
}
