@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Light.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 950;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Extrabold.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/neurial-grotesk/NeurialGrotesk-MediumItalic.otf') format('opentype');
}
