@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/proxima-soft/3420F8_A_0.eot');
  src: url('../fonts/proxima-soft/3420F8_A_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_A_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_A_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_A_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/proxima-soft/3420F8_5_0.eot');
  src: url('../fonts/proxima-soft/3420F8_5_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_5_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_5_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_5_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/proxima-soft/3420F8_7_0.eot');
  src: url('../fonts/proxima-soft/3420F8_7_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_7_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_7_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_7_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/proxima-soft/3420F8_6_0.eot');
  src: url('../fonts/proxima-soft/3420F8_6_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_6_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_6_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_6_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/proxima-soft/3420F8_8_0.eot');
  src: url('../fonts/proxima-soft/3420F8_8_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_8_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_8_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_8_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/proxima-soft/3420F8_2_0.eot');
  src: url('../fonts/proxima-soft/3420F8_2_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_2_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_2_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/proxima-soft/3420F8_0_0.eot');
  src: url('../fonts/proxima-soft/3420F8_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_0_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_0_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: normal;
  font-weight: 950;
  src: url('../fonts/proxima-soft/3420F8_4_0.eot');
  src: url('../fonts/proxima-soft/3420F8_4_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_4_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_4_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_4_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 950;
  src: url('../fonts/proxima-soft/3420F8_9_0.eot');
  src: url('../fonts/proxima-soft/3420F8_9_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_9_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_9_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_9_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/proxima-soft/3420F8_F_0.eot');
  src: url('../fonts/proxima-soft/3420F8_F_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_F_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_F_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_F_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/proxima-soft/3420F8_B_0.eot');
  src: url('../fonts/proxima-soft/3420F8_B_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_B_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_B_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_B_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/proxima-soft/3420F8_D_0.eot');
  src: url('../fonts/proxima-soft/3420F8_D_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_D_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_D_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_D_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/proxima-soft/3420F8_C_0.eot');
  src: url('../fonts/proxima-soft/3420F8_C_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_C_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_C_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_C_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/proxima-soft/3420F8_E_0.eot');
  src: url('../fonts/proxima-soft/3420F8_E_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_E_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_E_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_E_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/proxima-soft/3420F8_3_0.eot');
  src: url('../fonts/proxima-soft/3420F8_3_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_3_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_3_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaSoft';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/proxima-soft/3420F8_1_0.eot');
  src: url('../fonts/proxima-soft/3420F8_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-soft/3420F8_1_0.woff2') format('woff2'),
    url('../fonts/proxima-soft/3420F8_1_0.woff') format('woff'),
    url('../fonts/proxima-soft/3420F8_1_0.ttf') format('truetype');
}
